import React from "react"
import styled from 'styled-components'

const TextSection = (props) => (
	<BlockTitle>
		<p>
			{props.textSectionText}
		</p>
   </BlockTitle>
)

const BlockTitle = styled.section`
  text-align: left;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  margin: 50px 175px;
  padding-bottom: 80px;
  padding-top: 80px;
  width: 50%;

  @media (max-width: 640px){
    width: 100%;
    margin: 0;
  }
  
  @media (max-width: 1050px){
  	padding-bottom: 40px;
    padding-top: 84px;
  }
  @media (min-width: 541px){
  	
    padding-bottom: 32px;
    padding-top: 32px;
  }
`

export default TextSection;