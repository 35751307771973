import Img from 'gatsby-image';
import { chunk, sum } from 'lodash';
import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled from 'styled-components'
import { Box, Link } from 'rebass';
import carouselFormatters from '../../utils/carouselFormatters';

const ImageGallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints }) => {
	//Split images into groups of the given size
	// const rows = chunk(images, itemsPerRow);
	const aspectRatios = images.map(image => image.aspectRatio);

	// For each breakpoint, calculate the aspect ratio sum of each row's images
	const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
	  itemsPerRow =>
	    // Split images into groups of the given size
	    chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
	      // Sum aspect ratios of images in the given row
	      sum(rowAspectRatios),
	    ),
	);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

	const closeModal = () => setModalIsOpen(false);
	const openModal = (imageIndex) => {
	  setModalCurrentIndex(imageIndex);
	  setModalIsOpen(true);
	};

	return (
		<StyledImageGallery>
			{images.map((image, i) => (
				<Link
				  key={image.id}
				  href={image.originalImg}
				  onClick={(e) => {
				    e.preventDefault();
				    openModal(i);
				  }}
				 >
	        <Box
	          as={Img}
	          fluid={image}
	          title={image.caption}
	          alt={image.caption}
	          width={rowAspectRatioSumsByBreakpoints.map(
	            // Return a value for each breakpoint
	            (rowAspectRatioSums, j) => {
	              // Find out which row the image is in and get its aspect ratio sum
	              const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
	              const rowAspectRatioSum = rowAspectRatioSums[rowIndex];

	              return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`;
	            },
	          )}
	          css={`
	          	display: inline-block;
	          	vertical-align: middle;
	          	transition: filter 0.3s;
	          	:hover {
	          	  filter: brightness(87.5%);
	          	}
	          `}
	        />
	       </Link>
      ))}
			{ModalGateway && (
			  <ModalGateway>
			    {modalIsOpen && (
			      <Modal onClose={closeModal}>
			        <Carousel
			          views={images.map(({ originalImg, caption }) => ({
			            source: originalImg,
			            caption,
			          }))}
			          currentIndex={modalCurrentIndex}
			          formatters={carouselFormatters}
			          components={{ FooterCount: () => null }}
			        />
			      </Modal>
			    )}
			  </ModalGateway>
			)}

		</StyledImageGallery>
	);
};

const StyledImageGallery = styled.section`
	a {
	  pointer-events: none;
	}
	@media (min-width: 1025px){
	  padding-bottom: 80px;
	  padding-top: 80px;
	}
	@media (max-width: 1024px) and (min-width: 641px){
	  padding-bottom: 40px;
	  padding-top: 40px;
	}
	@media (max-width: 640px){
		padding-bottom: 32px;
		padding-top: 32px;
	}
`

export default ImageGallery;